<template>
  <slot />
</template>

<script setup>
import { useHeaderStore } from '@/stores/header'

const store = useHeaderStore()

store.showGnb(false)

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}

onMounted(() => {
  window.addEventListener('resize', appHeight)
  appHeight()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', appHeight)
})
</script>